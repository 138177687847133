body {
  &::-webkit-scrollbar {
    width: 1rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #000000;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #5C3CB4;
  }

  overflow-y: auto;
}
